import type { JwtPayload, Secret } from 'jsonwebtoken'
import { sign, verify } from 'jsonwebtoken'
import md5 from 'md5'
import * as jose from 'jose'

export async function authenticate(
  accessToken: string,
  accessTokenSecret: string,
): ReturnType<typeof verifyAccessToken> {
  return verifyAccessToken(accessToken, accessTokenSecret)
}

// Note: this matches the CreateAccessToken function in the Viewpoint web app, so the access tokens are synchronized between the two applications
export async function createAccessToken(
  username: string,
  secret: string,
  audience: string,
): Promise<string | undefined> {
  if (!secret || !audience) {
    return undefined
  }

  const jwt = await new jose.SignJWT({ id: username })
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setIssuer('cmERDC')
    .setAudience(audience)
    .setExpirationTime('15m')
    .sign(new TextEncoder().encode(secret))

  return jwt
}

export function createUpdatePasswordToken(
  username: string,
  secret: string,
  audience: string,
  expires = true,
): string | null {
  return sign(
    {
      username,
    },
    secret,
    {
      audience,
      expiresIn: expires ? '1h' : undefined,
    },
  )
}

export async function verifyAccessToken(
  accessToken: unknown,
  accessTokenSecret: string,
): Promise<{
  isAuthenticated: boolean
  payload?: jose.JWTPayload
}> {
  if (!accessToken) {
    return { isAuthenticated: false }
  }

  try {
    const { payload } = await jose.jwtVerify(
      accessToken as string,
      new TextEncoder().encode(accessTokenSecret),
    )

    return { isAuthenticated: true, payload }
  } catch (err) {
    return { isAuthenticated: false }
  }
}

export function verifyUpdatePasswordToken(
  updateToken: unknown,
  secret?: string,
):
  | { isAuthenticated: boolean; payload?: undefined; error?: undefined }
  | {
      isAuthenticated: boolean
      payload: string | JwtPayload
      error?: undefined
    }
  | { isAuthenticated: boolean; error: unknown; payload?: undefined } {
  if (!updateToken || !secret) {
    return { isAuthenticated: false }
  }

  try {
    const payload = verify(updateToken as string, secret as Secret)
    return payload
      ? { isAuthenticated: true, payload }
      : { isAuthenticated: false }
  } catch (err) {
    return { isAuthenticated: false, error: err }
  }
}

// export const verifyRefreshToken = refreshToken => {
//   if (!refreshToken) {
//     return { ok: false }
//   }

//   try {
//     const payload = verify(refreshToken, process.env.UPDATE_PASSWORD_TOKEN_SECRET)
//     return payload ? { ok: true, payload } : { ok: false }
//   } catch (err) {
//     return { ok: false }
//   }
// }

// export const sendRefreshToken = (cookies, token) => {
//   cookies.set('vprt', token, {
//     httpOnly: true,
//     path: '/refresh_token',
//   })
// }

/**
 * create an md5 hash of the username + password, separated every
 * 3 characters with '-' and converted to upper case
 * @param userName - the user's login identifier
 * @param password - the user's local authentication password
 * @returns string
 */
export const generatePasswordHash = (
  userName: string,
  password: string,
): string => {
  if (!userName || !password) {
    return ''
  }

  const hashArray = Array.from(md5(`${userName}${password}`))
  const hashedPassword = hashArray
    .reduce((accum, cur, index) => {
      const isEven = (index + 1) % 2 === 0
      return isEven && index !== hashArray.length - 1
        ? `${accum}${cur}-`
        : `${accum}${cur}`
    }, '')
    .toUpperCase()

  return hashedPassword
}
